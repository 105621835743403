import { GET_VEHICLES, SET_ERROR, CLEAR_STATE, SET_LOADING } from '../types';

const reducer = (state, action) => {
    switch (action.type) {
        case GET_VEHICLES:
            return {
                ...state,
                vehicles: action.payload,
                loading: false,
                error: null,
                count: action.count,
                success: 'Vehiculos Cargados',
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                vehicle: {},
                vehicles: [],
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return { ...state };
    }
};

export default reducer;
