import React, { useReducer } from 'react';
import AuthContext from './authContext';
import AuthReducer from './authReducer';
import api from '../../api/api';
import {
    USER_LOADED,
    LOGIN_SUCCESS,
    LOGOUT,
    LOGIN_FAIL,
    SET_LOADING,
    CLEAR_STATE,
    CLEAR_SUCCESS,
} from '../types';

const AuthState = (props) => {
    const initialState = {
        token: localStorage.getItem('token'),
        isAuthenticated: false,
        user: {},
        loading: false,
        error: null,
        success: null,
    };

    const [state, dispatch] = useReducer(AuthReducer, initialState);

    const clearState = () => dispatch({ type: CLEAR_STATE });

    const clearSuccess = () => dispatch({ type: CLEAR_SUCCESS });

    //Set Current User
    const loadUser = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Client-Name': 'carone',
            },
        };

        try {
            const res = await api.get(`/auth/me`, config);
            dispatch({
                type: USER_LOADED,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: LOGIN_FAIL, payload: err.response.data.error });
        }
    };

    //Login User
    const login = async (values) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Client-Name': 'carone',
            },
        };
        setLoading();

        try {
            const res = await api.post('/auth/login', values, config);
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            });

            loadUser();
        } catch (err) {
            dispatch({
                type: LOGIN_FAIL,
                payload: err.response.data.error,
            });
        }
    };

    // Logout
    const logout = () => dispatch({ type: LOGOUT });

    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                loading: state.loading,
                isAuthenticated: state.isAuthenticated,
                user: state.user,
                error: state.error,
                success: state.success,
                login,
                clearSuccess,
                loadUser,
                logout,
                clearState,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthState;
