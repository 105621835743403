import React, { useReducer } from 'react';
import StoreContext from './storeContext';
import StoreReducer from './storeReducer';
import api from '../../api/api';
import { GET_STORES, SET_ERROR, CLEAR_STATE, SET_LOADING } from '../types';

const StoreState = (props) => {
    const initialState = {
        stores: [],
        loading: false,
        error: null,
        success: null,
    };

    const [state, dispatch] = useReducer(StoreReducer, initialState);

    //Get Stores
    const getStores = async (params) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Client-Name': 'carone',
            },
        };
        clearState();
        setLoading();
        try {
            const res = await api.post(
                `/stores/searchDocuments`,
                params,
                config
            );
            dispatch({
                type: GET_STORES,
                payload: res.data.results,
                count: res.data.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <StoreContext.Provider
            value={{
                loading: state.loading,
                stores: state.stores,
                error: state.error,
                success: state.success,
                getStores,
                clearState,
                setLoading,
            }}
        >
            {props.children}
        </StoreContext.Provider>
    );
};

export default StoreState;
