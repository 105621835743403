import moment from 'moment';

/**
 * Generates an array of years from the current year to a specified number of years in the past, in ascending order.
 *
 * @param {number} yearsInPast - The number of years in the past to include in the array.
 * @returns {string[]} An array of years as strings, ordered from the oldest to the most recent.
 */

export const generateYears = (yearsInPast) => {
    const currentYear = moment().year();
    const years = [];

    for (let i = yearsInPast; i >= 0; i--) {
        years.push((currentYear - i).toString());
    }

    return years;
};
