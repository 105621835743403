import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useCompany from '../../hooks/useCompany';
import useStore from '../../hooks/useStore';
import useVehicle from '../../hooks/useVehicle';
import useLead from '../../hooks/useLead';
import SOURCES from '../../constants/sources';
import moment from 'moment';
import {
    Box,
    Menu,
    IconButton,
    ListItemText,
    ListItemIcon,
    MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Logout from '@mui/icons-material/Logout';
import { isReceptionist, isRockstar } from '../../utils/AuthRoles';
import { validPhone } from '../../utils/validPhone';
import {
    TAKE_CAR_IN_TRADES,
    YEARS,
    TIME_FRAMES,
    TYPE_LEAD_SHOWROOM,
    FROM_LEAD_QUEST,
} from '../../constants/Lead';
import startCase from 'lodash.startcase';
import { Form, useFormAnswers } from '@quillforms/renderer-core';
import '@quillforms/renderer-core/build-style/style.css';
import { registerCoreBlocks } from '@quillforms/react-renderer-utils';
registerCoreBlocks();

const FormData = () => {
    const { logout, user } = useAuth();
    const Company = useCompany();
    const Store = useStore();
    const Vehicle = useVehicle();
    const { createLead, clearState } = useLead();
    const formAnswers = useFormAnswers();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        Company.getCompanies();
    }, []);

    useEffect(() => {
        clearState();
    }, []);

    useEffect(() => {
        if (!user || !user?.tier?.name || !user._id) return;
        let defaultParams = {};
        if (isRockstar(user.tier._id || user.tier))
            return Store.getStores(defaultParams);
        if (user?.stores && user.stores.length > 0) {
            Vehicle.getVehicles({ make: user.stores[0].make._id });
            return Store.getStores({
                _id: user.stores.map((store) => store._id),
            });
        }
    }, [user]);

    const handleLogout = () => {
        logout();
    };

    useEffect(() => {
        if (formAnswers.store && formAnswers.store.value) {
            const data = JSON.parse(formAnswers.store.value);
            Vehicle.getVehicles({ make: data.make });
        }
    }, [formAnswers.store]);

    const submitLead = (data) => {
        const storeMake = JSON.parse(data?.store?.value);

        if (parseInt(data?.timeFrame?.value) === 0) {
            data.timeFrame.value = moment(0).format();
        }
        if (parseInt(data?.timeFrame?.value) === 1) {
            data.timeFrame.value = moment().add(1, 'month').format();
        }
        if (parseInt(data?.timeFrame?.value) === 2) {
            data.timeFrame.value = moment().add(2, 'months').format();
        }
        if (parseInt(data?.timeFrame?.value) === 3) {
            data.timeFrame.value = moment().add(3, 'months').format();
        }
        let LeadForm = {
            type: TYPE_LEAD_SHOWROOM,
            name: data?.NameUser?.value,
            email: data?.EmailUser?.value,
            phone: data?.phone_number?.value,
            store: storeMake?.store,
            source: data?.source?.value,
            vehicle: data?.vehicle?.value,
            carType: data?.type?.value,
            downPayment: data?.payment?.value || 0,
            year: moment(data?.year?.value).format('YYYY'),
            timeFrame: data?.timeFrame?.value,
            takeCarInTrade: data?.takeCarInTrade.value,
            make: storeMake?.make,
            from: FROM_LEAD_QUEST,
        };

        if (
            (user && user.tier && isRockstar(user.tier._id)) ||
            (user &&
                user.tier &&
                isReceptionist(user.tier._id) &&
                user.stores &&
                user.stores.length > 1)
        ) {
            createLead(LeadForm);
        }

        if (
            user &&
            user.tier &&
            isReceptionist(user.tier._id) &&
            user.stores &&
            user.stores.length === 1
        ) {
            createLead({
                ...LeadForm,
                store: user.stores[0]._id,
            });
        }
    };

    if (!localStorage.getItem('token')) return <Navigate to="/" />;

    return (
        <div style={{ width: '100%', height: '90vh' }}>
            <Box display="flex" flexDirection="row-reverse" mb={2} mt={1}>
                <IconButton onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>

                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Cerrar Sesión" />
                    </MenuItem>
                </Menu>
            </Box>
            <Form
                formId="1"
                formObj={{
                    blocks: [
                        {
                            name: 'welcome-screen',
                            id: 'jg1401r',
                            attributes: {
                                label: `<p style="font-size: 32px; font-weight: bold;">Hola Bienvenido</p>`,
                                description:
                                    'El proceso solo tomara unos pocos minutos',
                            },
                        },
                        {
                            name: 'short-text',
                            id: 'NameUser',
                            attributes: {
                                nextBtnLabel: 'Siguiente',
                                classnames: 'first-block',
                                required: true,
                                label: 'Nombres completo del cliente',
                            },
                        },
                        {
                            name: 'email',
                            id: 'EmailUser',
                            attributes: {
                                nextBtnLabel: 'Siguiente',
                                required: true,
                                label: 'Correo Electrónico',
                            },
                        },

                        {
                            name: 'number',
                            id: 'phone_number',
                            attributes: {
                                nextBtnLabel: 'Siguiente',
                                required: true,
                                label: 'Teléfono (10 Dígitos)',
                            },
                        },
                        {
                            name: 'dropdown',
                            id: 'store',
                            attributes: {
                                nextBtnLabel: 'Siguiente',
                                required: true,
                                label: 'Agencia de Interés',
                                choices:
                                    user &&
                                    user._id &&
                                    Store.stores
                                        .sort((a, b) =>
                                            `${a.make.name} ${a.name}`.localeCompare(
                                                `${b.make.name} ${b.name}`
                                            )
                                        )
                                        .map((store) => {
                                            return {
                                                value: JSON.stringify({
                                                    store: `${store._id}`,
                                                    make: `${store.make._id}`,
                                                }),
                                                label: `${startCase(
                                                    store.make.name +
                                                        ' ' +
                                                        store.name
                                                )}`,
                                            };
                                        }),
                            },
                        },
                        {
                            name: 'dropdown',
                            id: 'source',
                            attributes: {
                                nextBtnLabel: 'Siguiente',
                                required: true,
                                label: '¿Cómo se enteró de nosotros?',
                                choices: SOURCES.sort((a, b) =>
                                    a.name.localeCompare(b.name)
                                ).map((source) => {
                                    return {
                                        value: `${source.id}`,
                                        label: `${startCase(source.name)}`,
                                    };
                                }),
                            },
                        },
                        {
                            name: 'dropdown',
                            id: 'type',
                            attributes: {
                                nextBtnLabel: 'Siguiente',
                                required: true,
                                label: 'Tipo de Unidad',
                                choices: [
                                    {
                                        value: 'nuevo',
                                        label: 'Nuevo',
                                    },
                                    {
                                        value: 'seminuevo',
                                        label: 'Seminuevo',
                                    },
                                ],
                            },
                        },
                        {
                            name: 'dropdown',
                            id: 'vehicle',
                            attributes: {
                                nextBtnLabel: 'Siguiente',
                                required: true,
                                label: 'Modelo de Interés',
                                choices: Vehicle.vehicles
                                    .sort((a, b) =>
                                        `${a.make.name + ' ' + a.model}`.localeCompare(
                                            `${b.make.name + ' ' + b.model}`
                                        )
                                    )
                                    .map((vehicle) => {
                                        return {
                                            value: `${vehicle._id}`,
                                            label: `${startCase(
                                                vehicle.make.name +
                                                    ' ' +
                                                    vehicle.model
                                            )}`,
                                        };
                                    }),
                            },
                        },

                        {
                            name: 'dropdown',
                            id: 'year',
                            attributes: {
                                nextBtnLabel: 'Siguiente',
                                required: true,
                                label: 'Año del Modelo',
                                choices: YEARS.map((year) => {
                                    return {
                                        value: `${year}`,
                                        label: `${year}`,
                                    };
                                }),
                            },
                        },
                        {
                            name: 'dropdown',
                            id: 'takeCarInTrade',
                            attributes: {
                                nextBtnLabel: 'Siguiente',
                                required: true,
                                label: 'Desea tomar el auto a cuenta?',
                                choices: TAKE_CAR_IN_TRADES.map((item) => {
                                    return {
                                        value: `${item.value}`,
                                        label: `${item.label}`,
                                    };
                                }),
                            },
                        },
                        {
                            name: 'dropdown',
                            id: 'timeFrame',
                            attributes: {
                                nextBtnLabel: 'Siguiente',
                                required: true,
                                label: 'Tiempo de Compra',
                                choices: TIME_FRAMES.map((item) => {
                                    return {
                                        value: `${item.value}`,
                                        label: `${item.label}`,
                                    };
                                }),
                            },
                        },
                        {
                            name: 'number',
                            id: 'payment',
                            attributes: {
                                nextBtnLabel: 'Siguiente',
                                required: true,
                                label: 'Enganche',
                            },
                        },
                        {
                            name: 'statement',
                            id: 'statement',
                            attributes: {
                                buttonText: 'Enviar',
                                label: `<center><p style="font-weight: bold; font-size: 28px;">Verifique que la información sea correcta</p></center>`,
                                description: `<ul style="list-style: none; padding: 0; margin: 0; width: 100%;">
                <li style="background-color: #f6f6f6; border-bottom: 1px solid #e5e5e5; border-top: 1px solid #e5e5e5; padding: 10px; width: 100%; margin-top: 10px; display:flex;">
                  <span style="display: inline-block; width: 50%; font-weight: bold;">Nombre:</span>
                  <span style="display: inline-block; width: 50%;">{{field:NameUser}}</span>
                </li>
                <li style="background-color: #f0f0f0; border-bottom: 1px solid #e5e5e5; padding: 10px; width: 100%; display:flex;">
                  <span style="display: inline-block; width: 50%; font-weight: bold;">Correo electrónico:</span>
                  <span style="display: inline-block; width: 50%;">{{field:EmailUser}}</span>
                </li>
                <li style="background-color: #f6f6f6; border-bottom: 1px solid #e5e5e5; padding: 10px; width: 100%; display:flex;">
                  <span style="display: inline-block; width: 50%; font-weight: bold;">Teléfono:</span>
                  <span style="display: inline-block; width: 50%;">{{field:phone_number}}</span>
                </li>
                <li style="background-color: #f0f0f0; border-bottom: 1px solid #e5e5e5; padding: 10px; width: 100%; display:flex;">
                  <span style="display: inline-block; width: 50%; font-weight: bold;">Agencia de Interés:</span>
                  <span style="display: inline-block; width: 50%;">{{field:store}}</span>
                </li>
                <li style="background-color: #f6f6f6; border-bottom: 1px solid #e5e5e5; padding: 10px; width: 100%; display:flex;">
                  <span style="display: inline-block; width: 50%; font-weight: bold;">Fuente:</span>
                  <span style="display: inline-block; width: 50%;">{{field:source}}</span>
                </li>
                <li style="background-color: #f0f0f0; border-bottom: 1px solid #e5e5e5; padding: 10px; width: 100%; display:flex;">
                  <span style="display: inline-block; width: 50%; font-weight: bold;">Tipo de Unidad:</span>
                  <span style="display: inline-block; width: 50%;">{{field:type}}</span>
                </li>
                <li style="background-color: #f6f6f6; border-bottom: 1px solid #e5e5e5; padding: 10px; width: 100%; display:flex;">
                  <span style="display: inline-block; width: 50%; font-weight: bold;">Modelo de Interés:</span>
                  <span style="display: inline-block; width: 50%;">{{field:vehicle}}</span>
                </li>
                <li style="background-color: #f0f0f0; border-bottom: 1px solid #e5e5e5; padding: 10px; width: 100%; display:flex;">
                  <span style="display: inline-block; width: 50%; font-weight: bold;">Año del Modelo:</span>
                  <span style="display: inline-block; width: 50%;">{{field:year}}</span>
                </li>
                <li style="background-color: #f6f6f6; border-bottom: 1px solid #e5e5e5; padding: 10px; width: 100%; display:flex;">
                  <span style="display: inline-block; width: 50%; font-weight: bold;">Auto a cuenta:</span>
                  <span style="display: inline-block; width: 50%;">{{field:takeCarInTrade}}</span>
                </li>
                <li style="background-color: #f0f0f0; border-bottom: 1px solid #e5e5e5; padding: 10px; width: 100%; display:flex;">
                  <span style="display: inline-block; width: 50%; font-weight: bold;">Tiempo de compra:</span>
                  <span style="display: inline-block; width: 50%;">{{field:timeFrame}}</span>
                </li>
                <li style="background-color: #f6f6f6; border-bottom: 1px solid #e5e5e5; padding: 10px; width: 100%; display:flex;">
                  <span style="display: inline-block; width: 50%; font-weight: bold;">Enganche:</span>
                  <span style="display: inline-block; width: 50%;">$ {{field:payment}}</span>
                </li>
              </ul> `,
                            },
                        },
                    ],
                    theme: {
                        font: 'Roboto',
                        buttonsBgColor: '#083484',
                        logo: {
                            src: '',
                        },
                        questionsColor: '#000',
                        answersColor: '#083484',
                        buttonsFontColor: '#fff',
                        buttonsBorderRadius: 25,
                        errorsFontColor: '#fff',
                        errorsBgColor: '#f00',
                    },
                    messages: {
                        'label.hintText.enter':
                            'Presiona <strong> Enter ↵</strong>',
                        'block.defaultThankYouScreen.label':
                            'Se ha creado un registro Exitosamente!',
                        'label.progress.percent':
                            '{{progress:percent}}% completado!',
                        'label.errorAlert.required':
                            'Este campo es obligatorio!',
                        'label.errorAlert.number': 'Solo coloca números',
                        'label.errorAlert.email': 'Coloca un correo valido!',
                        'label.errorAlert.selectionRequired':
                            '¡Por favor, haga al menos una selección!',
                        'label.errorAlert.maxCharacters':
                            'Maximo de caracteres alcanzado!',
                        'label.submitBtn': 'Enviar',
                        'block.longText.hint':
                            'Pulsa <strong>Shift ⇧ + Enter ↵</strong> para hacer un salto de línea',
                        'block.email.placeholder':
                            'Ingresa el Correo Electrónico',
                        'block.number.placeholder': 'Ingresa el número',
                        'block.shortText.placeholder': 'Ingresa la información',
                        'block.longText.placeholder': 'Ingresa la información',
                        'block.dropdown.placeholder': 'Selecciona una opcion',
                    },
                }}
                beforeGoingNext={({
                    setIsFieldValid,
                    setIsPending,
                    currentBlockId,
                    answers,
                    setFieldValidationErr,
                    setIsCurrentBlockSafeToSwipe,
                    goToField,
                    goNext,
                }) => {
                    if (
                        currentBlockId === 'phone_number' &&
                        !validPhone(answers[currentBlockId].value)
                    ) {
                        setIsFieldValid(currentBlockId, false);
                        setFieldValidationErr(
                            currentBlockId,
                            'Teléfono no valido'
                        );
                        setIsCurrentBlockSafeToSwipe(false);
                    } else {
                        setIsFieldValid(currentBlockId, true);
                        setFieldValidationErr(currentBlockId, '');
                        setIsCurrentBlockSafeToSwipe(true);
                        goNext();
                    }
                }}
                onSubmit={(
                    data,
                    {
                        completeForm,
                        setIsSubmitting,
                        goToBlock,
                        setSubmissionErr,
                    }
                ) => {
                    submitLead(formAnswers);
                    setTimeout(() => {
                        setIsSubmitting(false);
                        completeForm();
                    }, 5000);

                    setTimeout(() => {
                        window.location.reload();
                    }, 7000);
                }}
            />
        </div>
    );
};

export default FormData;
