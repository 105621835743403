import React from 'react';
import {
    Box,
    Card,
    CardContent,
    Container,
    Divider,
    Typography,
} from '@mui/material';

import JWTLogin from './JWTLogin';
import { useTheme } from '@mui/system';
import Page from '../../components/Page';

const LoginView = () => {
    const theme = useTheme();

    const styles = {
        root: {},
        cardContainer: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            outline: 'none',
            width: 410,
            maxHeight: '100%',
            overflowY: 'auto',
            maxWidth: '100%',
        },
        cardContent: {
            padding: theme.spacing(4),
            display: 'flex',
            flexDirection: 'column',
        },
        currentMethodIcon: {
            height: 60,
            '& > img': {
                width: 'auto',
                maxHeight: '100%',
            },
        },
        rightText: {
            float: 'right',
        },
        header: {
            fontSize: 40,
            fontWeight: 100,
            letterSpacing: 3,
            color: 'white',
        },
        subheader: {
            fontSize: 20,
            fontWeight: 100,
            letterSpacing: 3,
            color: 'white',
        },
    };

    return (
        <Page>
            <Container style={styles.cardContainer}>
                <Box display="flex" justifyContent="center">
                    <Typography
                        variant="caption"
                        color="textPrimary"
                        style={styles.header}
                    >
                        Bienvenido
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center" pb={3}>
                    <Typography
                        variant="caption"
                        color="textPrimary"
                        style={styles.subheader}
                    >
                        Ingresa tus credenciales
                    </Typography>
                </Box>
                <Card elevation={0}>
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="center"
                        p={2}
                    >
                        <Typography color="textPrimary" variant="h3">
                            Login
                        </Typography>
                    </Box>
                    <Divider />
                    <CardContent style={styles.cardContent}>
                        <Box flexGrow={1} mt={1}>
                            <JWTLogin />
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </Page>
    );
};

export default LoginView;
