import { CREATE_LEAD, SET_ERROR, CLEAR_STATE, SET_LOADING } from '../types';

const reducer = (state, action) => {
    switch (action.type) {
        case CREATE_LEAD:
            return {
                ...state,
                loading: false,
                error: null,
                lead: action.payload,
                success: 'Lead creado exitosamente!',
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_STATE:
            return {
                lead: {},
                leads: [],
                chart: [],
                loading: false,
                error: null,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return { ...state };
    }
};

export default reducer;
