import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

const BackAlert = ({
    title,
    message,
    titleProps = {},
    messageProps = {},
    ...rest
}) => {
    return (
        <Alert {...rest}>
            {title && <AlertTitle {...titleProps}>{title}</AlertTitle>}
            <div {...messageProps}>{message}</div>
        </Alert>
    );
};
export default BackAlert;
