import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from 'react-router-dom';

//Views
import Login from './views/Login';
// import Quest from "./views/Quest"
import QuestWithStepper from './views/QuestStepper';

//Context
import AuthState from './contexts/auth/AuthState';
import AlertState from './contexts/alert/AlertState';
import CompanyState from './contexts/company/CompanyState';
import StoreState from './contexts/store/StoreState';
import SourceState from './contexts/source/SourceState';
import VehicleState from './contexts/vehicle/VehicleState';
import LeadState from './contexts/lead/LeadState';

//Layout
import MainLayout from './layout/MainLayout';
import LoginLayout from './layout/LoginLayout';

//Theme
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { indigo, blue } from '@mui/material/colors';

const theme = createTheme({
    palette: {
        primary: {
            main: blue[300],
        },
        secondary: {
            main: indigo[300],
        },
    },
});

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <AuthState>
                    <CompanyState>
                        <StoreState>
                            <SourceState>
                                <VehicleState>
                                    <LeadState>
                                        <AlertState>
                                            <Routes>
                                                <Route
                                                    element={<LoginLayout />}
                                                >
                                                    <Route
                                                        exact
                                                        path="/login"
                                                        element={<Login />}
                                                    />
                                                </Route>
                                                <Route
                                                    exact
                                                    element={<MainLayout />}
                                                >
                                                    <Route
                                                        exact
                                                        path="/form"
                                                        element={
                                                            <QuestWithStepper />
                                                        }
                                                    />
                                                    <Route
                                                        path="*"
                                                        element={
                                                            <Navigate to="/login" />
                                                        }
                                                    />
                                                </Route>
                                            </Routes>
                                        </AlertState>
                                    </LeadState>
                                </VehicleState>
                            </SourceState>
                        </StoreState>
                    </CompanyState>
                </AuthState>
            </Router>
        </ThemeProvider>
    );
};

export default App;
